import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import BlocImageGaucheTexte from '../components/BlocImageGaucheTexte'
import BlocImageDroiteTexte from '../components/BlocImageDroiteTexte'
import BlocVertTexteCTA from '../components/BlocVertTexteCTA'
import Testimonials from '../components/Testimonials'
import Calendly from '../components/Calendly'

function Home(props) {
  const [language, setLanguage] = useState('1')
  const [nodeLocale, setNodeLocale] = useState(null)
  const [popupVideo, setPopupVideo] = useState(false)
  const [popupCalendly, setPopupCalendly] = useState(false)

  const getLanguage = (data) => {
    if (data === '0') {
      setLanguage(0)
      setNodeLocale('en-US')
    } else if (data === '1') {
      setLanguage(1)
      setNodeLocale('fr')
    }
  }

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  useEffect(() => {
    if ((typeof localStorage !== 'undefined') || (typeof sessionStorage !== 'undefined') || (typeof window !== 'undefined')) {
      if ((localStorage.getItem('language') === '0') || (sessionStorage.getItem('langFooter') === 'english')) {
        setLanguage(0)
        setNodeLocale('en-US')
      } else if ((localStorage.getItem('language') === '1') || (sessionStorage.getItem('langFooter') === 'french')) {
        setLanguage(1)
        setNodeLocale('fr')
      }
    }
  }, [nodeLocale,language])

  const keyPress = () => {}

  const data = props.data
  const {
    headline,
    excerpt,
    blocCtaBtns,
    modularArea,
    headlineBlocsIconesTextes,
    blocsIconesTextes,
    blocsIconesTextes2,
    headlineIconesTextes2,
    descriptionIconesTextes2,
    heroImage,
    blocCta,
    blocVertCta,
    headlineCheckAnOtherFeature,
    checkAnOtherFeature
  } = data.allContentfulHome.nodes[`${language}`]
  const header = data.allContentfulSousMenu.nodes
  const postNode = data.allContentfulHome.nodes[`${language}`]

  return (
    <div>
      <Header headerContent={header} infoPage={props} language={language} getLanguage={getLanguage}/>
      <Layout data={data}>
        <SEO pagePath="/" postNode={postNode} pageSEO />

        <div className="wrapper" id="wrapper-home">
          <main className={`home ${nodeLocale}`}>
            <section className="section-1">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="box-item">
                      <h1 className="headline">{headline}</h1>
                      <h2 className="description">{excerpt}</h2>
                      <div className="box-btns">
                        <button className="btn btn-primary" onClick={(e) => setPopupCalendly(true)}><span>{blocCtaBtns.ctaLienGauche}</span></button>
                        <Link to={blocCtaBtns.ctaUrlLienDroite} title=""><button className="btn btn-primary btn-blanc"><span>{blocCtaBtns.ctaLienDroite}</span></button></Link>
                      </div>
                    </div>
                  </div>
                  <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="box-bitmap">
                      <img src={heroImage.file.url} alt={heroImage.description} key/>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container">
                <p>Ils nous font confiance :</p>
                <div className="logos-clients">
                  <div><img src="../logos/TheBureau.png" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></div>
                  <div><img className="hiptown" src="../logos/Hiptown.png" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></div>
                  <div><img src="../logos/NewtownSquare.png" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></div>
                  <div><img src="../logos/Kima.png" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></div>
                  <div><img src="../logos/Reseau-Entreprendre.png" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></div>
                  <div><img src="../logos/Wilco.png" alt="Simplifier la gestion des bureaux et des espaces de travail modernes"/></div>
                </div>
                <hr/>
              </div>
            </section>

            {modularArea.map((item) =>
              item.typeBlocImage === 'Image à gauche' ? <BlocImageGaucheTexte data={item} key={item.headline} /> :
              item.typeBlocImage === 'Image à droite' ? <BlocImageDroiteTexte data={item} key={item.texte.texte} /> : null)}

            <section className="section-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 columns">
                    <h2 className="large">{headlineCheckAnOtherFeature}</h2>
                  </div>
                </div>
                <div className="row">
                  {checkAnOtherFeature.map((item) =>
                    <div className="columns col-lg-4 col-md-4 col-sm-4 col-xs-12 col-12" key={item.headline}>
                      <Link to={item.lien}>
                        <div className="box-bloc">
                          <h3 className="text-center">{item.headline}</h3>
                          <p className="text-center">{item.description.description}</p>
                          <img src={item.image.file.url} alt=""/>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </section>

            {modularArea
              .filter((item) => item.__typename === 'ContentfulCarouselClients')
              .map((item) => <Testimonials data={item} key />)}

            <BlocVertTexteCTA data={blocVertCta}/>

            <section className="section-7">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-7 col-md-7 col-sm-12 col-12">
                    <h2 className="large">{headlineIconesTextes2}</h2>
                  </div>
                  <div className="columns col-lg-5 col-md-5 col-sm-12 col-12">
                    <p className="big-description">{descriptionIconesTextes2.descriptionIconesTextes2}</p>
                  </div>
                </div>

                <div className="row">
                  {blocsIconesTextes2.map((item) =>
                    <div className="columns col-lg-4 col-md-4 col-sm-12 col-12" key={item.id}>
                      <div className="box-bloc">
                        <div className="trapeze-rouge" />
                        <div className="box-icon">
                          <img src={item.icone.file.url} alt={item.icone.description}/>
                        </div>
                        <h3>{item.headline}</h3>
                        <p>{item.texte.texte}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="section-8">
              <div className="container">

                <div className="row">
                  <div className="columns col-lg-6 col-md-6 col-sm-12 col-12">
                    <h2 className="large">{headlineBlocsIconesTextes}</h2>
                  </div>
                </div>

                <div className="row">
                  {blocsIconesTextes.map((item) =>
                    <div className="columns col-lg-4 col-md-4 col-sm-12 col-12" key={item.id}>
                      <div className="box-icon">
                        <img src={item.icone.file.url} alt={item.icone.description}/>
                      </div>
                      <h3>{item.headline}</h3>
                      <p>{item.texte.texte}</p>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="section-6">
              <div className="container">
                <div className="box-item">
                  <div>
                    <h2 className="large blanc">{blocCta.headline}</h2>
                    <Link to={blocCta.ctaUrlLienGauche} title=""><button className="btn btn-primary btn-rouge"><span>{blocCta.ctaLienGauche}</span></button></Link>
                    <button onClick={(e) => setPopupCalendly(true)} className="btn btn-primary btn-blanc btn-hover-tundora"><span>{blocCta.ctaLienDroite}</span></button>
                  </div>
                </div>
              </div>
            </section>

            <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>

            <div className={ popupVideo === true ? 'popup-video open' : 'popup-video' } role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => setPopupVideo(false)}>
              <div className="container">
                <div className="close" role="button" tabIndex={0} onKeyPress={keyPress} onClick={(e) => setPopupVideo(false)} />
                {popupVideo === true ?
                  <div className="responsive-iframe">
                    <iframe src="https://www.youtube.com/embed/VxPOQSib0YE" title="youTubeVideo" frameBorder="0" allowscriptaccess="always" webkitAllowFullScreen mozallowfullscreen allowFullScreen/>
                  </div> : null}
              </div>
            </div>
          </main>
        </div>
      </Layout>
      <Footer getLanguage={getLanguage}/>
    </div>
  )
}

export const query = graphql`
  query {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        titre
        icone {
          file {
            url
          }
        }
        baselineMenu {
          baselineMenu
        }
      }
    }
    allContentfulHome {
      nodes {
        id
        node_locale
        titre
        headline
        excerpt
        blocCtaBtns {
          ctaLienDroite
          ctaLienGauche
          ctaTexteDroite {
            ctaTexteDroite
          }
          ctaTexteGauche {
            ctaTexteGauche
          }
          ctaUrlLienDroite
          ctaUrlLienGauche
        }
        heroImage {
          description
          file {
            url
          }
        }
        metaDescription {
          metaDescription
          internal {
            content
          }
        }
        modularArea {
          ... on ContentfulBlocImageTexte {
            headline
            ancre
            typeBlocImage
            lienInterne
            lienTitreCta
            image {
              description
              file {
                url
              }
              contentful_id
            }
            texte {
              texte
            }
            listePucesDeFeatures {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulCarouselClients {
            __typename
            headline
            id
            clients {
              logo {
                description
                file {
                  url
                }
              }
              nom
              poste
              citation {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        headlineBlocsIconesTextes
        blocsIconesTextes {
          headline
          icone {
            description
            file {
              url
            }
          }
          id
          texte {
            texte
          }
        }
        descriptionIconesTextes2 {
          descriptionIconesTextes2
        }
        headlineIconesTextes2
        blocsIconesTextes2 {
          headline
          icone {
            description
            file {
              url
            }
          }
          id
          texte {
            texte
          }
        }
        headlineCheckAnOtherFeature
        checkAnOtherFeature {
          headline
          description {
            description
          }
          image {
            file {
              url
            }
          }
          lien
        }
        blocVertCta {
          lienTitreCta
          lienInterne
          headline
        }
        blocCta {
          headline
          ctaLienGauche
          ctaLienDroite
          ctaUrlLienDroite
          ctaUrlLienGauche
        }
      }
    }
  }
`

export default Home
